import { FormFooter } from '@cloudflare/component-form';
import { createStyledComponent } from '@cloudflare/style-container';

/**
 * FormFooter that uses flexbox instead of fixed-right alignment.
 */
export const FlexFormFooter = createStyledComponent(
  () => ({
    display: 'flex',
    justifyContent: 'space-between',
  }),
  FormFooter
);
