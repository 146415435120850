import * as React from 'react';

import { Box } from '@cloudflare/component-box';
import { Loading } from '@cloudflare/component-loading';

export const LoadingBox = (): JSX.Element => (
  <Box padding="1em" textAlign="center">
    <Loading size="2.5x" />
  </Box>
);
