import * as React from 'react';

import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
} from '@cloudflare/component-table';

import { ClickToCopy } from '@app/components/ClickToCopy';

type AccountsTableProps = {
  accounts?: {
    username: string;
    password: string;
  }[];
};

export const AccountsTable: React.FC<AccountsTableProps> = ({
  accounts = [],
}): JSX.Element => {
  if (!accounts.length) {
    return <p>No accounts</p>;
  }

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Username</TableCell>
          <TableCell>Password</TableCell>
        </TableRow>
      </TableHead>

      <TableBody>
        {accounts.map(({ username, password }) => {
          const truncatedPassword = `${password.substring(0, 11)}...`;

          return (
            <TableRow key={username}>
              <TableCell>
                <ClickToCopy text={username}>{username}</ClickToCopy>
              </TableCell>
              <TableCell py="0">
                <ClickToCopy text={password} displayText={truncatedPassword} />
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};
