import * as React from 'react';

import { Box } from '@cloudflare/component-box';

type ErrorBoxProps = {
  error: Error | string;
};

export const ErrorBox: React.FC<ErrorBoxProps> = ({ error }): JSX.Element => (
  <Box padding="1em" color="red" border="solid 1px red" borderRadius="5px">
    <h1>Something went wrong</h1>

    <pre>{(error as Error)?.message || error}</pre>
  </Box>
);
