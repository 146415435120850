import * as React from 'react';
import { Route, Switch } from 'wouter';
import { QueryClient, QueryClientProvider } from 'react-query';

import { StyleProvider } from '@cloudflare/style-provider';

import { AppHeader } from '@app/components/AppHeader';
import { E2EManagerPage } from '@app/pages/E2EManagerPage';
import { AccountsPage } from '@app/pages/accounts/AccountsPage';
import { AddAccountPage } from '@app/pages/accounts/AddAccountPage';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // Default cache stale time of 2 minutes
      staleTime: 120000,
    },
  },
});

export const App = (): JSX.Element => (
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <StyleProvider>
        <>
          <AppHeader />

          <Switch>
            <Route path="/accounts" component={AccountsPage} />
            <Route path="/accounts/add" component={AddAccountPage} />

            {/* Default route */}
            <Route component={E2EManagerPage} />
          </Switch>
        </>
      </StyleProvider>
    </QueryClientProvider>
  </React.StrictMode>
);
