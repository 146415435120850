import * as React from 'react';
import { Link } from 'wouter';
import { useMutation, useQueryClient } from 'react-query';

import { Box } from '@cloudflare/component-box';
import { ButtonGroup, Button } from '@cloudflare/component-button';
import { Form, FormFieldset, FormLabel } from '@cloudflare/component-form';
import { Input } from '@cloudflare/component-input';
import { Radio, RadioGroup } from '@cloudflare/component-radio';

import { FlexFormFooter } from '@app/components/FlexFormFooter';
import { ApiEnvironment, createE2EAccount } from '@app/services/e2eAccounts';

export const AddAccountPage = (): JSX.Element => {
  const [envField, setEnvField] = React.useState<ApiEnvironment>('production');
  const [usernameField, setUsernameField] = React.useState('');
  const [passwordField, setPasswordField] = React.useState('');
  const [accountTypeField, setAccountTypeField] = React.useState<
    undefined | string
  >(undefined);

  const [showSubmitStatusText, setShowSubmitStatusText] = React.useState(false);

  const queryClient = useQueryClient();

  const addAccountMutation = useMutation<
    Record<string, unknown>,
    Error,
    {
      env: ApiEnvironment;
      username: string;
      password: string;
      accountType: undefined | string;
    }
  >({
    mutationFn: ({
      env = 'production',
      username,
      password,
      accountType = undefined,
    }) => createE2EAccount(env, username, password, accountType),
    onSuccess: () => {
      queryClient.invalidateQueries('accounts/getE2EAccountsList');
    },
  });

  const resetSubmitStatusText = () => {
    setShowSubmitStatusText(false);
  };

  /* Basic and arbitrary form validations */
  const isFormValid = () => {
    let isValid = true;

    if (!usernameField || !usernameField.includes('@')) {
      isValid = false;
    }

    if (!passwordField) {
      isValid = false;
    }

    if (accountTypeField) {
      if (accountTypeField.length < 3) {
        isValid = false;
      }
    }

    return isValid;
  };

  const handleSubmit = () => {
    if (!isFormValid()) {
      return;
    }

    addAccountMutation.mutate({
      env: envField,
      username: usernameField,
      password: passwordField,
      accountType: accountTypeField,
    });
  };

  const formSubmitStatusText = addAccountMutation.isError
    ? `An error occurred: ${addAccountMutation.error.message}`
    : addAccountMutation.isSuccess
    ? 'Account created!'
    : '';

  const formSubmitStatusColor = addAccountMutation.isError
    ? '#FC574A'
    : addAccountMutation.isSuccess
    ? '#55D584'
    : 'inherit';

  return (
    <Box padding="1em">
      <h1>Add E2E Account</h1>

      <Form
        layout="vertical"
        onSubmit={(event) => {
          event.preventDefault();

          setShowSubmitStatusText(true);
          handleSubmit();
        }}
      >
        <FormFieldset legend="Account Details">
          <FormLabel htmlFor="environment">E2E Environment</FormLabel>
          <RadioGroup
            value={envField}
            onChange={(value) => {
              resetSubmitStatusText();
              setEnvField(value as ApiEnvironment);
            }}
            marginBottom="16px"
          >
            <Radio label="Production" name="production" value="production" />
            <Radio label="Staging" name="staging" value="staging" />
          </RadioGroup>

          <FormLabel htmlFor="username">Email Address (Username)</FormLabel>
          <Input
            name="username"
            type="email"
            value={usernameField}
            onChange={(event) => {
              resetSubmitStatusText();
              setUsernameField(event.target.value);
            }}
          />

          <FormLabel htmlFor="password">Password</FormLabel>
          <Input
            name="password"
            type="text"
            value={passwordField}
            onChange={(event) => {
              resetSubmitStatusText();
              setPasswordField(event.target.value);
            }}
          />

          <FormLabel htmlFor="account_type">
            Account Type (optional, leave blank if unsure)
          </FormLabel>
          <Input
            name="account_type"
            type="text"
            value={accountTypeField}
            onChange={(event) => {
              resetSubmitStatusText();
              setAccountTypeField(event.target.value ?? undefined);
            }}
          />
        </FormFieldset>

        <FlexFormFooter>
          <div style={{ color: formSubmitStatusColor }}>
            {showSubmitStatusText && formSubmitStatusText}
          </div>

          <ButtonGroup spaced>
            <Link href="/accounts">
              <a>
                <Button type="plain">Cancel</Button>
              </a>
            </Link>

            <Button
              type="success"
              loading={addAccountMutation.isLoading}
              disabled={!isFormValid() || addAccountMutation.isLoading}
              submit
            >
              Add Account
            </Button>
          </ButtonGroup>
        </FlexFormFooter>
      </Form>
    </Box>
  );
};
