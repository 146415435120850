import * as React from 'react';
import { Link } from 'wouter';

import { Header, NavList, NavItem } from '@cloudflare/component-header';
import { LogoHeader } from '@cloudflare/component-logo';

export const AppHeader = (): JSX.Element => (
  <Header>
    <Link href="/">
      <a>
        <LogoHeader />
      </a>
    </Link>

    <NavList>
      <NavItem>
        <Link href="/accounts">
          <a>Manage E2E Accounts</a>
        </Link>
      </NavItem>
    </NavList>
  </Header>
);
