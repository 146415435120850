import * as React from 'react';
import { Link } from 'wouter';
import { useQuery } from 'react-query';

import { Box } from '@cloudflare/component-box';
import { Button } from '@cloudflare/component-button';
import { Select } from '@cloudflare/component-select';

import { AccountsTable } from '@app/components/accountsTable/AccountsTable';
import { ErrorBox } from '@app/components/ErrorBox';
import { LoadingBox } from '@app/components/LoadingBox';
import {
  ApiEnvironment,
  E2EAccount,
  getE2EAccountsList,
} from '@app/services/e2eAccounts';

export const AccountsPage = (): JSX.Element => {
  const [env, setEnv] = React.useState<ApiEnvironment>('production');

  const {
    isLoading,
    error,
    data: accounts,
  } = useQuery<E2EAccount[], Error>(['accounts', 'getE2EAccountsList', env], {
    queryFn: () => getE2EAccountsList(env),
  });

  if (isLoading) {
    return <LoadingBox />;
  }

  return (
    <Box padding="1em">
      <h1>Manage E2E Accounts</h1>

      {error && <ErrorBox error={error} />}

      <Box my="1em" display="flex" alignItems="center">
        <Box flex="1 0">
          <Link href="/accounts/add">
            <a>
              <Button type="success">Create new E2E account</Button>
            </a>
          </Link>
        </Box>

        <Box flex="1 0">
          <Select
            label="E2E Environment"
            value={env}
            options={[
              { value: 'production' as ApiEnvironment, label: 'Production' },
              { value: 'staging' as ApiEnvironment, label: 'Staging' },
            ]}
            onChange={({ value }: { value: ApiEnvironment; label: string }) => {
              setEnv(value as ApiEnvironment);
            }}
          />
        </Box>
      </Box>

      <AccountsTable accounts={accounts} />
    </Box>
  );
};
