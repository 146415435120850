const API_BASE_PATH = '/api/accounts';

const makeStagingApiPath = (path = ''): string =>
  path.includes('env=staging')
    ? path
    : path.includes('?')
    ? `${path}&env=staging`
    : `${path}?env=staging`;

type ApiResponse<Result = unknown> = {
  success: boolean;
  result: Result;
};

const makeApiRequest = async <ResultType = unknown>(
  path = '',
  requestOptions?: RequestInit
) => {
  const requestPath = path ? `${API_BASE_PATH}${path}` : API_BASE_PATH;

  const requestResponse = await fetch(requestPath, requestOptions);

  const responseText = await requestResponse.text();

  let response: ApiResponse<ResultType> | string;

  try {
    response = JSON.parse(responseText);
  } catch (_error) {
    response = responseText;
  }

  /* API should return JSON unless an error occurs, so assume a text response is
  an API error */
  if (typeof response === 'string') {
    throw Error(`Unexpected response from E2E Accounts API: ${response}`);
  }

  if (!response.success) {
    throw Error(`E2E Accounts API returned an unsuccesful response`);
  }

  if (!response.result) {
    throw Error(`'result' property is missing from E2E Accounts API response`);
  }

  return response.result;
};

export type ApiEnvironment = 'staging' | 'production';

type E2EAccountsResult = {
  [username: string]: string;
};

export type E2EAccount = { username: string; password: string };

/**
 * Fetch E2E accounts then transform them into an array of account objects.
 */
export const getE2EAccountsList = async (
  env: ApiEnvironment = 'production'
): Promise<E2EAccount[]> => {
  const apiPath = env === 'staging' ? makeStagingApiPath() : '';

  const accounts = await makeApiRequest<E2EAccountsResult>(apiPath);

  return Object.keys(accounts).map((username) => ({
    username,
    password: accounts[username],
  }));
};

/**
 * Fetch E2E accounts then try to return an account object for `username`
 */
export const getE2EAccount =
  (env: ApiEnvironment = 'production', username: string) =>
  async (): Promise<E2EAccount> => {
    if (!username || typeof username !== 'string') {
      throw Error(`getE2EAccount: Invalid username -- received: ${username}`);
    }

    const apiPath = env === 'staging' ? makeStagingApiPath() : '';

    const accounts = await makeApiRequest<E2EAccountsResult>(apiPath);

    if (!Object.keys(accounts).includes(username)) {
      throw Error(`getE2EAccount: Username ${username} not found`);
    }

    return { username, password: accounts[username] };
  };

/**
 * Creates an E2E account from `username` and `password`. Password values are
 * encrypted by the E2E Accounts API.
 */
export const createE2EAccount = async (
  env: ApiEnvironment = 'production',
  username: string,
  password: string,
  accountType: undefined | string
): Promise<Record<string, unknown>> => {
  if (!username || typeof username !== 'string') {
    throw Error(`createE2EAccount: Invalid username -- received: ${username}`);
  }

  if (!password || typeof password !== 'string') {
    throw Error(`createE2EAccount: Invalid password -- received: ${password}`);
  }

  const apiPath = env === 'staging' ? makeStagingApiPath() : '';

  const body = JSON.stringify({
    username,
    password,
    account_type: accountType,
  });

  const result = await makeApiRequest<Record<string, unknown>>(apiPath, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body,
  });

  return result;
};
