import * as React from 'react';

import {
  ClickToCopy as BaseClickToCopy,
  IClickToCopyProps,
} from '@cloudflare/component-click-to-copy';
import { createStyledComponent } from '@cloudflare/style-container';

const ClickToCopyContainer = createStyledComponent(
  () => ({
    '& > button': {
      display: 'initial',
      width: '100%',

      '& > div': {
        display: 'flex',
        alignItems: 'center',
        height: 'auto',

        '& > div': {
          display: 'block',
          height: 'auto',

          '&:nth-child(2)': {
            float: 'none',
            marginTop: 'initial',
            border: 'dashed 1px rgb(89, 89, 89)',
          },
        },
      },
    },
  }),
  'div'
);

/**
 * Overrides the jank styles of the base ClickToCopy component.
 */
export const ClickToCopy: React.FC<IClickToCopyProps> = (
  props
): JSX.Element => (
  <ClickToCopyContainer>
    <BaseClickToCopy {...props} />
  </ClickToCopyContainer>
);
